import React from "react";
import { Row, Col } from "react-bootstrap";
import {
	IoChevronBackCircleOutline,
	IoChevronForwardCircleOutline,
} from "react-icons/io5";
import { DashedLine } from "../../styled-components/common";
import { nextButtonStyle } from "../flashCards/categories/style";
import { HomeStyle } from "../home/styles";
import { Layout } from "../home/Layout";
import VokabLinkButton from "./components/VokabLinkButton";

export default function VocabularyHomeStatic() {
	return (
		<Layout>
			<HomeStyle>
				<Row
					className="d-flex align-items-center "
					style={{ height: "calc(100vh - 130px)" }}
				>
					<Col xs={12} lg={6} className="order-last">
						<div className="container-fluid margin-top_l p-0 col-8 ">
							<h1>சொற்கள் • vokab</h1>
							<DashedLine />

							<p>
								<b>vokab</b> is a <b>free</b> and <b>multilingual</b> vocabulary
								software, designed to aid language-learning students across the
								world.
							</p>

							<p>
								Use vokab.org’s extensive database to enhance your{" "}
								<b>English - Tamil</b> vocabulary skills as you learn with our
								course.
							</p>
							<VokabLinkButton className="d-flex justify-content-center" />
						</div>
					</Col>
					<Col className="mx-auto order-first" >
						<div className="mt-3 mt-lg-0"></div>
						<div className="d-flex align-items-center justify-content-center">
							<div className="me-4">
								<button className="border-0" style={nextButtonStyle}>
									{" "}
									<IoChevronBackCircleOutline
										className="IoChevronWidth"
										color="#57587F"
									/>
								</button>
							</div>
								<img style={{
									width:"60%",
									height:"auto",
									borderRadius:"20%"
								}} src="/vokab-logo.png" alt="" className="img-fluid" />
		
							<div className="ms-4">
								<button className="border-0" style={nextButtonStyle}>
									<IoChevronForwardCircleOutline
										className="IoChevronWidth"
										color="#57587F"
									/>
								</button>
							</div>
						</div>
					</Col>
				</Row>
			</HomeStyle>
		</Layout>
	);
}
